
import { Vue, Component } from "vue-property-decorator";
import { commitSwitchLanguage } from "@/store/admin/mutations";
import { readLanguage } from "@/store/admin/getters";
import pdf from "vue-pdf";
import { api } from "@/api";
import { readToken } from "@/store/main/getters";
import ImageUploadComponent from "@/components/ImageUploadComponent.vue";

@Component({
  components: {
    ImageUploadComponent,
  },
})
export default class Visit extends Vue {
  //for uploading the picture
  public avatar = { formData: null, imageURL: null, imageFile: null };
  public saving: boolean = false;
  public saved: boolean = false;
  public date: Date = new Date();
  public comment: string = "";
  public show: boolean = false;
  public anamnese: string = "";
  public dialog: boolean = false;

  public vaccstatarr = ["Tetanus", "Influenca", "Herpes"];
  public vaccstat: String[] = [];
  public husbandryarr = [
    "Box",
    "Paddockbox",
    "Paddockbox + Weide",
    "Offenstall",
    "Weide",
  ];
  public husbandry: String[] = [];
  public abrasionstatearr = ["None", "Some", "Normal", "A lot", "Very much"];
  public abrasionstate = "";
  public onetonine = ["1", "2", "3", "4", "5", "6", "7", "8", "9"];
  public nutrition = "";

  get language() {
    return readLanguage(this.$store);
  }

  public swtichLanguageEng() {
    console.log("Englisch");
    //True means eng
    if (this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  public swtichLanguageDe() {
    //False means de
    console.log("DEUTSCH");
    if (!this.language) {
      commitSwitchLanguage(this.$store);
    }
  }

  // @Watch('avatar')
  public handler() {
    this.saved = false;
  }

  public uploadImage() {
    this.saving = true;
  }

  public reset() {
    this.avatar = { formData: null, imageURL: null, imageFile: null };
  }

  public save() {
    this.dialog = !this.dialog;
  }

  public async mounted() {}

  get windowWidthDividedBy4() {
    var width = (window.innerWidth - 100) / 4;
    return width + "px";
  }
}
